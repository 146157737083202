import * as React from 'react'
import { Container, VisuallyHidden, Grid, Box, Heading } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Link from '../components/link'
import FlatBorder from '../components/funky-border'
import ReactRouterLink from '../components/link'

const NotFoundPage = ({ data: { allShopifyCollection } }) => {
  return (
    <Layout>
      <Seo title="404" />

      <VisuallyHidden as="h1">404</VisuallyHidden>
      <Container py={20}>
        <Heading as="h2" size="lg" mb={4}>
          Sorry something went wrong, that page was not found.
        </Heading>
        <Heading as="h4" size="md" mb={4}>
          Were you looking for one of these?
        </Heading>
        <Grid
          templateColumns={[
            '1fr',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
          ]}
          columnGap={6}
          rowGap={[12, 16, 20]}
        >
          {allShopifyCollection.nodes.map(
            (collection) =>
              collection.title &&
              collection.image && (
                <Box key={`collection.handle -col`}>
                  <Heading as="h2" size="lg">
                    {collection.title}
                  </Heading>
                  <Link to={`/collections/${collection.handle}`}>
                    <FlatBorder tags={[collection.handle]}>
                      <GatsbyImage
                        alt=""
                        image={
                          collection.image.localFile.childImageSharp
                            .gatsbyImageData
                        }
                      />
                    </FlatBorder>
                  </Link>
                </Box>
              )
          )}
        </Grid>
        <Heading as="h3" size="md">
          If it isn't products you're looking for, there are some helpful links
          below or you can contact us
          <Link as={ReactRouterLink} variant="inline" to={'/contact'}>
            here
          </Link>
        </Heading>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  {
    allShopifyCollection {
      nodes {
        description
        title
        handle
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                formats: [AUTO, WEBP]
                quality: 40
                width: 400
              )
            }
          }
        }
      }
    }
  }
`
